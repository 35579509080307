html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  position: relative;
}

#canvas {
  background-color: #202020;
}
